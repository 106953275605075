import React from 'react';
import SimpleMap from '../../components/SimpleMap';
import addressIcon from '../../images/address.svg';
import emailIcon from '../../images/email.svg';
import Obfuscate from 'react-obfuscate';
import './Map.scss';

const Map = () => {
  return (
    <div className="contact-info">
      <div className="contact-info__container">
        <div className="contact-info__wrapper">
          <div className="contact-info__text-wrapper">
            <h2 className="contact-info__title">Contact Information</h2>
            <div className="contact-info__content-wrapper">
              <div className="contact-info__address-wrapper">
                <img src={addressIcon} alt="" />
                <p>
                  5 Earlsfort Terrace,
                  <br /> Dublin 2, D02CK83, Ireland.
                </p>
              </div>
              <div className="contact-info__email-wrapper">
                <img src={emailIcon} alt="" />
                <p>
                  <Obfuscate className="contact-info__email-link" email="amd@ww-dublin.com" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-info__map-wrapper">
          <SimpleMap />
        </div>
      </div>
    </div>
  );
};

export default Map;
