import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import Fade from 'react-reveal/Fade';
import './Tabs.scss';

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className="tabs">
        <div className="tab-list__wrapper">
          <ul className="tab-list">
            {children.map((child, i) => {
              const { label } = child.props;
              return <Tab activeTab={activeTab} key={i} label={label} onClick={onClickTabItem} />;
            })}
          </ul>
          <div className="tab-list__border"></div>
        </div>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return (
              <>
                <Fade>
                  <h3 className="tab-content__heading">{child.props.label}</h3>
                  <p className="tab-content__description">{child.props.children}</p>
                </Fade>
              </>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
