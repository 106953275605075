import React from 'react';
import Member from './Member';
import './Team.scss';
import Breandan from '../../images/team/breandan-23-compressed.jpg';
import Ronan from '../../images/team/ronan-23-compressed.jpg';

const ronanbio = (
  <p>
    Ronan Horgan has 25+ years’ experience in financial markets. Previous to W&amp;W Investment
    Managers, he ran the Global Partners desk at Davy Asset Management. He oversaw the provision of
    investment management services to third party funds. Prior to Davy, Ronan ran his own
    proprietary trading Firm RODA Investments, developing and trading multi-asset systematic
    strategies. While in London, Ronan was Global Income partner at CF Global Trading, outsourced
    trading for Asset Managers. His career started with Susquehanna International Group as a
    derivatives trader.
    <br />
    <br />
    Ronan is a Chartered Director, holds a Bachelor of Commerce from UCD, a Diploma in Cybersecurity
    from UCD, and is a Qualified Technical Analyst (MSTA), Trader (Xetra), Securities and Financial
    Derivatives level 3 (CISI).
  </p>
);

const breandanbio = (
  <p>
    Qualified Chartered Accountant, with PwC, 8 years experience specialising in financial services.
    Recruited in 2001 as Head of Finance with responsibility for the capital and financial
    management functions of WWAMI. During this time Mr. Ó Braoin’s experience and knowledge
    developed to include all facets of WWAMI.
  </p>
);

const members = [
  {
    name: 'Ronan Horgan',
    position: 'CEO',
    bio: ronanbio,
    imageSrc: Ronan,
  },
  {
    name: 'Breandán Ó Braoin',
    position: 'Managing Director',
    bio: breandanbio,
    imageSrc: Breandan,
  },
];

const Team = () => {
  let TeamMembers = members.map((member, i) => {
    return <Member member={member} key={i} />;
  });

  return (
    <section id="team" className="team">
      <div className="team__container">
        <div className="team__text">
          <h2 className="team__title">Strength in unity</h2>
          <p className="team__description">
            The W&amp;W Asset Management team comprises a wealth of experience across all facets of
            the fund management industry. A culture of collective thinking and drive within the team
            is a key cornerstone and ensures that our client is central to everything that we do.
          </p>
        </div>
        <div className="team__members">{TeamMembers}</div>
      </div>
    </section>
  );
};

export default Team;
