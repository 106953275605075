import React from 'react';
import Fade from 'react-reveal/Fade';
import './About.scss';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImg from 'gatsby-background-image';

const About = () => {
  const data = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: { eq: "office.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section id="about" className="about">
      <BackgroundImg
        fluid={data.image.childImageSharp.fluid}
        draggable={false}
        className="about__image-wrapper"
        title="5 Earlsfort Terrace, image courtesy of IPUT"
      >
        <div className="about__image-gradient-border"></div>
      </BackgroundImg>
      <Fade>
        <div className="about__content-wrapper">
          <h2 className="about__title">About Us</h2>
          <p>
            Incorporated in Ireland in 1999, our aim is to deliver a comprehensive and flexible fund
            management service offering centred around our clients. We provide a gateway for
            establishing, launching and managing fund structures in partnership with our clients.
          </p>
          <p>
            W&amp;W Asset Management Ireland DAC is wholly owned by W&amp;W Asset Management GmbH, a
            member of the Wüstenrot &amp; Württembergische Group.
          </p>
          <p>
            The Company provides fund management services to W&amp;W Asset Management GmbH managing
            €40bn on behalf of W&amp;W AG, a public listed entity, with a €75bn asset holding.
          </p>
          <p>
            The W&amp;W Gruppe companies can look back on a long history. Their roots go back to
            1828 and thus combine more than 190 years of experience in serving their clients.
          </p>
          <p>
            Regulated by the Central Bank of Ireland under the European Communities (Undertakings
            for Collective Investment in Transferable Securities) Regulations 2011, and (Alternative
            Investment Fund Managers Directive) Regulations 2013.
          </p>
        </div>
      </Fade>
    </section>
  );
};

export default About;
