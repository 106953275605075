import React from 'react';
import './Member.scss';

const Member = ({ member, order, children }) => {
  const { name, position, bio, imageSrc } = member;
  return (
    <div className={`member ${imageSrc ? '' : 'member--no-portrait'}`}>
      <a href="#0" aria-labelledby={`person${order}`}></a>
      {imageSrc && <img className="member__portrait" src={imageSrc} alt={`Portrait of ${name}`} />}
      <div className="member__overlay">
        <div className="member__title">
          <h3 className="member__name" id={`person${order}`} aria-hidden="true">
            {name}
          </h3>
          <p className="member__position">{position}</p>
        </div>
        <div className="member__description">{bio}</div>
      </div>
    </div>
  );
};

export default Member;
