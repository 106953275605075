import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Partners from '../components/Partners/Partners';
import About from '../components/About/About';
import Services from '../components/Services/Services';
import Team from '../components/Team/Team';
import Map from '../components/Map/Map';
import Contact from '../components/Contact/Contact';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <LandingBanner title="Comprehensive &amp; Flexible Fund Management" />
      <About />
      <Services />
      <Team />
      <Partners />
      <Map />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
