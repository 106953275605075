import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tabs from '../Tabs/Tabs';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Services.scss';

const services = [
  {
    title: 'Management Company - UCITS/AIFM',
    description:
      'WWAMI is authorised by the Central Bank of Ireland and supports funds structured as unit trusts, common contractual funds, Irish Collective Asset-management Vehicles (ICAVs) and Investment Limited Partnerships. WWAMI has extensive experience in managing both UCITS and AIFMD by providing tailor made solutions for our clients. In engaging with WWAMI an asset manager avoids the regulatory, financial and operational burden. WWAMI assumes responsibility for all local regulatory and compliance requirements.',
  },
  {
    title: 'Designated Persons - UCITS/AIFM',
    description:
      'WWAMI appoints Designated Persons to self-managed investment companies and management companies under the UCITS and AIFMD regime. The Designated Persons fulfil the executive substance requirement of these entities. The Designated Person is a pre-approved control function under the Fitness & Probity Regulations of the Central Bank Reform Act 2010.',
  },
  {
    title: 'Fund Establishment',
    description:
      'Expertise and experience in the establishment of fund structures from design to registration of the fund. W&W has experience across a broad spectrum of fund types including UCITS, QIAIFs, Fund of Funds, Private Debt, Private Equity and Infrastructure Funds. Approval with the Central Bank of Ireland takes 24-hours for funds established on our existing ICAV platform.',
  },
  {
    title: 'Money Laundering Reporting Officer (MLRO)',
    description:
      'Regulators globally continue to focus on money laundering and the possibility that funds may present opportunities for money launderers. W&W offers a specialist MLRO service to funds. This service operates independently of a regulated fund’s administrator/ transfer agent. The MLRO monitors the administrator’s AML procedures, carries out on-site inspections, reports to the Board of the fund and provides AML training.',
  },
  {
    title: 'Compliance & Regulatory Support',
    description:
      'WWAMI has the depth and expertise to provide guidance and on-going support on an extensive range of compliance, regulatory and operational matters.',
  },
  {
    title: 'Service Partners Selection',
    description:
      'WWAMI has access to a wide network of service partners and has the relevant industry knowledge to assist in the selection of service partners which best fit our clients requirements.',
  },
  {
    title: 'Risk Management',
    description:
      'WWAMI provides a risk management service to UCITS and AIFs enabling such funds to perform the risk management functions required to ensure compliance with the UCITS Regulations and AIFMD.',
  },
  {
    title: 'Irish Resident Directors',
    description:
      'WWAMI offers managers a highly experienced panel of non-executive directors who adhere to the highest standards of corporate governance and bring current industry knowledge and expertise to those boards on which they serve.',
  },
];

const Services = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const TabList = services.map((service, i) => (
    <div key={i} label={service.title}>
      {service.description}
    </div>
  ));

  const AccordionList = services.map((service, i) => (
    <Accordion
      classes={{ root: 'service__accordion', expanded: 'service__accordion--expanded' }}
      expanded={expanded === `panel${i}`}
      onChange={handleChange(`panel${i}`)}
      key={i}
    >
      <AccordionSummary
        classes={{
          root: 'service__accordion-summary',
          content: 'service__accordion-summary-content',
        }}
        expandIcon={<ExpandMoreIcon classes={{ root: 'service__accordion-icon' }} />}
        aria-controls={`panel${i}bh-content`}
        id={`panel${i}bh-header`}
      >
        <p className="services__expandable-title">{service.title}</p>
      </AccordionSummary>
      <AccordionDetails classes={{ root: 'service__accordion-details' }}>
        <p className="services__expandable-description">{service.description}</p>
      </AccordionDetails>
    </Accordion>
  ));

  return (
    <section id="services" className="services">
      <Fade>
        <div className="services__container">
          <h2 className="services__title">Fund Management Solutions</h2>
          <div className="services__tabs-wrapper">{TabList && <Tabs>{TabList}</Tabs>}</div>
          <div className="services__expandable-wrapper">{AccordionList}</div>
        </div>
      </Fade>
    </section>
  );
};

export default Services;
