import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import mapPin from '../images/map-pin.svg';

const styles = require('./GoogleMapStyles.json');

const AnyReactComponent = () => (
  <img style={{ marginTop: '-116px', marginLeft: '-25px' }} src={mapPin} alt="Map Pin" />
);

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 53.334568,
      lng: -6.2572752,
    },
    zoom: 17,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyD-46kG3hIvX4pAk3ivXDKhEQZzPugZWrk' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          defaultOptions={{
            disableDefaultUI: true, // disable default map UI
            draggable: false, // make map draggable
            keyboardShortcuts: false, // disable keyboard shortcuts
            scaleControl: false, // allow scale controle
            scrollwheel: false, // allow scroll wheel
            styles: styles, // change default map styles
          }}
        >
          <AnyReactComponent lat={53.334568} lng={-6.2572752} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
