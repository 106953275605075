import React, {useState} from 'react';
import axios from 'axios';
import './Contact.scss';

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"


const Contact = () => {
  const [token, setToken] = useState()

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailMessage, setShowFailMessage] = useState(false);

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    const data = new FormData(form)
    data.append("g-recaptcha-response", token)

    console.log(data, "data")
    axios({
      method: "post",
      url: "https://getform.io/f/75a1f686-1da5-4d08-9bf5-7e6eec7ff5b1",
      data
    })
      .then(r => {
        setShowSuccessMessage(true);
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        console.log('r', r);
        setShowFailMessage(true);
        handleServerResponse(false, r.response.data.error, form);
      })
  }

  return (
    <div className="contact" id="contact">
      <div className="contact__background">
        <div className="contact__form-wrapper">
          <h2 className="contact__title">Send us a message</h2>
          <div className="contact__form-background">
          <GoogleReCaptchaProvider reCaptchaKey="6LcpljQaAAAAADaWP-5GMTar-SXC65B11kogsNj5">
            <form
              className="contact__form"
              onSubmit={handleOnSubmit}
            >
              <div className="form__input-group">
                <label htmlFor="name">Full name</label>
                <input name="name" type="text" id="name" placeholder="e.g. John Smith" />
              </div>
              <div className="form__input-group">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  type="email"
                  id="email"
                  placeholder="e.g. johnsmith@company.com"
                />
              </div>
              <div className="form__input-group">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  type="text"
                  id="message"
                  placeholder="Type your message here"
                />
              </div>
              <div className="form__button-wrapper">
                <button 
                  className="form__button--submit" 
                  type="submit"
                >
                  Send
                </button>
              </div>
              <GoogleReCaptcha
                onVerify={token => {
                  setToken(token)
                }}
              />
            </form>
            {showSuccessMessage && 
              <div className="alert">
                Thank you, your message has been sent. 
              </div>
            }
            {showFailMessage && 
              <div className="alert">
                Something is not right, please try again.
              </div>
            }
            </GoogleReCaptchaProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
